<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="appstore"></a-icon>
          <span>闲置网口</span>
        </a-space>
      </template>
      <span>发现网络设备中的闲置网口。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :getTableDataFunc="getTableDataFunc"
        :columnDefaultSelected="columnDefaultSelected"
        :columnsAll="columnsAll"
        :toolsList="['setting', 'reload', 'search', 'leftTool']"
        :refresh="refresh"
        :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
        :table-operation-width="120"
      >
        <!-- 工具栏 -->
        <template #leftTool>
          <create-button
            @click="$refs.createDrawer.show()"
            v-permission="{action: 'base.network_interface_usage_report.create', effect: 'disabled'}"
          >
          </create-button>
        </template>
        <!-- 表格自定义列 -->
        <template #name="{ slotProps }">
          <a-tooltip placement="topLeft" :title="slotProps.text">
            <a
              v-if="slotProps.record.completed"
              @click="
                $router.push({
                  name: 'NetworkInterfaceUsageReportDetail',
                  params: {
                    id: slotProps.record.id
                  }
                })
              "
            >
              {{ slotProps.text }}
            </a>
            <span v-else>{{ slotProps.text }}</span>
          </a-tooltip>
        </template>
        <template #range="{ slotProps }">
          {{ `${slotProps.record.datetime_from} ~ ${slotProps.record.datetime_to}` }}
        </template>
        <template #completed="{ slotProps }">
          <boolean-tag
            false-color="#1890ff"
            false-name="生成中"
            true-name="已生成"
            :value="slotProps.text"
          ></boolean-tag>
        </template>
        <template #operation="{ slotProps }">
          <delete-button
          v-permission="{action: 'base.network_interface_usage_report.delete', effect: 'disabled'}"
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>
    </a-card>

    <create-drawer ref="createDrawer" @ok="confirm"></create-drawer>
  </div>
</template>

<script>
import {
  deleteNetworkInterfaceUsageReport,
  getNetworkInterfaceUsageReportList
} from '@/api/report'
import ListLayout from '@/components/ListLayout.vue'
import DeleteButton from '@/components/button/DeleteButton.vue'
import CreateButton from '@/components/button/CreateButton.vue'
import BooleanTag from '@/components/tag/BooleanTag.vue'
import CreateDrawer from './modules/CreateDrawer.vue'

export default {
  name: 'NetworkInterfaceUsageReportList',
  components: {
    BooleanTag,
    CreateDrawer,
    DeleteButton,
    ListLayout,
    CreateButton
  },
  data () {
    return {
      getTableDataFunc: getNetworkInterfaceUsageReportList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'range',
          title: '统计周期',
          width: 300,
          scopedSlots: {
            customRender: 'range'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'completed',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'completed'
          },
          filters: [
            {
              text: '已完成',
              value: 'true'
            },
            {
              text: '处理中',
              value: 'false'
            }
          ],
          filterMultiple: false
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: ['name', 'range', 'created_at', 'completed'],
      refresh: false,
      tableSelectData: []
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteNetworkInterfaceUsageReport(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      }
      this.refresh = !this.refresh
    }
  }
}
</script>
