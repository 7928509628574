<template>
  <a-drawer
    :destroy-on-close="true"
    title="闲置网口报表创建"
    :visible="visible"
    :width="1000"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="开始时间" prop="datetimeStart">
        <a-date-picker
          v-model="form.datetimeStart"
          :disabled-date="disabledDate"
        ></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="网络设备">
        <div style="display: flex">
          <a-form-model-item prop="sourceTarget" style="margin-bottom: 0">
            <a-select
              v-model="form.sourceTarget"
              :options="options.sourceTarget"
              style="width: 160px"
            ></a-select>
          </a-form-model-item>
          <template v-if="form.sourceType">
            <a-form-model-item
              v-if="form.sourceTarget === 'group'"
              prop="groups"
              :rules="[
                {
                  message: '请选择' + $t(`source_type.${form.sourceType}`) + '群组',
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px; margin-bottom: 0"
            >
              <group-select
                v-model="form.groups"
                :source-type="form.sourceType"
                style="width: 440px"
              ></group-select>
            </a-form-model-item>
            <a-form-model-item
              v-else-if="form.sourceTarget === 'selected'"
              prop="sources"
              :rules="[
                {
                  message: '请选择' + $t(`source_type.${form.sourceType}`),
                  required: true,
                  trigger: 'change'
                }
              ]"
              style="margin-left: 16px; margin-bottom: 0"
            >
              <multi-source-select
                v-model="form.sources"
                :source-type="form.sourceType"
                style="width: 440px"
              ></multi-source-select>
            </a-form-model-item>
          </template>
        </div>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import { createNetworkInterfaceUsageReport } from '@/api/report'
import GroupSelect from '@/components/select/GroupSelect'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'
import { sourceTargets } from '@/utils/const'

export default {
  name: 'CreateDrawer',
  components: {
    GroupSelect,
    MultiSourceSelect
  },
  data () {
    return {
      visible: false,
      form: {
        name: '',
        datetimeStart: undefined,
        sourceType: 'network_device',
        sourceTarget: undefined
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        datetimeStart: [
          {
            message: '请选择开始时间',
            required: true,
            trigger: 'change'
          }
        ],
        sourceTarget: {
          message: '请选择网络设备范围',
          required: true,
          trigger: 'change'
        }
      },
      options: {
        sourceTarget: sourceTargets.map(value => {
          return {
            title: this.$t(`source_target.${value}`),
            value
          }
        })
      },
      loading: false
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    closeDrawer () {
      this.form = {
        name: '',
        datetimeStart: undefined,
        sourceType: 'network_device',
        sourceTarget: undefined
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    disabledDate (date) {
      return date && date > moment().endOf('day')
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            datetime_from: this.form.datetimeStart.format('YYYY-MM-DD'),
            source_type: this.form.sourceType,
            source_target: this.form.sourceTarget
          }
          if (this.form.sourceTarget === 'group') {
            params.group_ids = this.form.groups.map(group => group.id)
          } else if (this.form.sourceTarget === 'selected') {
            params.source_ids = this.form.sources.map(source => source.id)
          }
          this.loading = true
          createNetworkInterfaceUsageReport(params)
            .then(res => {
              this.$message.success(res.message)
              this.$emit('ok')
              this.closeDrawer()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>
